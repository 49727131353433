<template>
  <div class="mt-3" v-if="sortedNotes.length > 0">
    <div v-for="note in sortedNotes" :key="note.id" class="note-item">
      <div class="py-2 pl-2">
        <span class="font-weight-bold">#{{ note.round_num + 1 }}</span>
        <span class="font-weight-bold ml-3">
          {{ displayTime(note.round_millis) }}
        </span>
        <div>
          {{ note.text }}
        </div>
        <div class="tags">
          <b-badge class="mr-2" v-for="tag in note.tags" :key="tag.id">{{ tag.name }}</b-badge>
        </div>
      </div>
      <div class="pr-2">
        <router-link class="btn btn-sm btn-outline-primary" :to="mapPath(note)">
          <span class="tabe__btn-value">2D Map</span>
        </router-link>
      </div>
    </div>
  </div>
  <div v-else class="text-center mt-3">No data</div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import px from 'vue-types'
import { mapGetters } from 'vuex'

import { pxNullable } from '@/components/Map/types'
import formatTime from '@/components/Map/utils/formatTime.js'

export default {
  components: { BBadge },
  props: {
    notes: pxNullable(px.arrayOf(px.object)),
    tags: pxNullable(px.arrayOf(px.object)),
  },
  inject: ['matchData'],
  computed: {
    ...mapGetters({
      getMapById: 'static/getMapById',
    }),
    sortedNotes() {
      if (!this.notes) {
        return []
      }
      return [...this.notes].sort((a, b) => {
        if (a.round_num - b.round_num != 0) {
          return a.round_num - b.round_num
        }
        return a.round_millis - b.round_millis
      })
    },
  },
  methods: {
    displayTime(time) {
      return formatTime(100000 - time)
    },
    mapPath(note) {
      return {
        path: `/map/${this.getMapById(this.matchData?.info?.map)?.name}/${this.matchData?.info?.teams[0]?.id}`,
        query: { matches: [this.matchData?.info?.id], round: note.round_num, round_millis: note.round_millis },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.note-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: $gray-900;

  &:nth-of-type(odd) {
    background-color: rgba(255 255 255 / 5%);
  }
}
</style>
