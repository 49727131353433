import axios from '@/axios.js'

/**
 *
 * @param {string} text
 * @param {Array} tags
 * @param {number} round_num
 * @param {number} round_millis
 * @param {any} extra_data
 * @return {Promise<API_NOTE>}
 */
export async function createMatchNote({ matchId, text, tags, round_num, round_millis, extra_data = {} }) {
  const response = await axios.post(`/val/v3/esport/matches/${matchId}/notes`, {
    text,
    tags: tags?.map(tag => tag.id),
    round_num,
    round_millis,
    extra_data,
  })
  return response.data
}

export async function createScrimNote({ scrimId, text, tags, round_num, round_millis, extra_data = {} }) {
  const response = await axios.post(`/val/v3/scrim/matches/${scrimId}/notes`, {
    match: scrimId,
    text,
    tags: tags?.map(tag => tag.id),
    round_num,
    round_millis,
    extra_data,
  })
  return response.data
}

export async function getNoteTags({ cancelToken } = {}) {
  const { data } = await axios.get('/val/v3/tags', { cancelToken })
  return data.tags || []
}

export async function getMatchNotes({ matchId, isCollegiate = false }, { cancelToken } = {}) {
  const type = isCollegiate ? 'collegiate' : 'esport'
  const { data } = await axios.get(`/val/v3/${type}/matches/${matchId}/notes`, {
    cancelToken,
  })
  return data.notes || []
}

export async function getScrimNotes(scrimId, { cancelToken } = {}) {
  const { data } = await axios.get(`/val/v3/scrim/matches/${scrimId}/notes`, {
    params: { match: scrimId },
    cancelToken,
  })
  return data.notes || []
}
