<template>
  <ApiLoadingController :fetch="fetch" :params="params">
    <template #default="{ data }">
      <MatchNotes v-bind="data" />
    </template>
  </ApiLoadingController>
</template>

<script>
import px from 'vue-types'

import { getMatchNotes, getNoteTags } from '@/api/notes'
import ApiLoadingController from '@/components/controllers/ApiLoadingController.vue'
import MatchNotes from '@/components/Match.old/MatchNotes.vue'

export default {
  name: 'MatchNotesPage',
  components: {
    MatchNotes,
    ApiLoadingController,
  },
  props: {
    matchId: px.string.isRequired,
  },
  computed: {
    params() {
      return this.matchId
    },
    isCollegiate() {
      return this.$route.params.isCollegiate || false
    },
  },
  methods: {
    async fetch(matchId, options) {
      const notes = await getMatchNotes({ matchId, isCollegiate: this.isCollegiate }, options)
      const noteTags = await getNoteTags(options)
      notes.forEach(note => {
        note.tags = note.tags.map(tagId => noteTags.find(tag => tagId === tag.id))
      })

      return {
        notes,
        noteTags,
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
